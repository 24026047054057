import { useField } from 'formik';
import { useCallback, useContext, useMemo } from 'react';
import { GroupBase, Props } from 'react-select';
import { AsyncProps } from 'react-select/async';
import {
  AsyncSelectField as BaseAsyncSelectField,
  AsyncSelectProps,
  CustomOptionProps,
  SelectField as BaseSelectField,
} from 'Shared/Components/FormFields/SelectField';
import { FieldContext } from 'Shared/Contexts';
import { isEmptyValue } from 'Shared/Support/valueHelpers';

export type CustomSelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Omit<
  Props<Option, IsMulti, Group>,
  'options'
> & {
  labelKey?: string;
  valueKey?: string;
  options: Props<Option, IsMulti, Group>['options'] | { [key: string | number]: string } | string[];
};

export const SelectField = <Option extends CustomOptionProps, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  valueKey,
  labelKey,
  ...props
}: CustomSelectProps<Option, IsMulti, Group>) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error(SelectField.displayName + ' with missing FieldWrapper component');
  }

  const [field, , { setValue, setTouched }] = useField(name);

  const getOptionValue = useCallback(props.getOptionValue ?? ((option) => option[valueKey === undefined ? 'value' : valueKey]), [
    props.getOptionValue,
    valueKey,
  ]);

  const getOptionLabel = useCallback(props.getOptionLabel ?? ((option) => option[labelKey === undefined ? 'label' : labelKey]), [
    props.getOptionLabel,
    labelKey,
  ]);

  const formattedOptions = useMemo(() => {
    if (props.options === undefined) {
      return;
    }

    if (Array.isArray(props.options)) {
      return props.options;
    }

    return Object.entries(props.options).map(([value, label]) => ({ label, value }));
  }, [props.options]);

  const selectedOptionOrOptions = useMemo(() => {
    if (!formattedOptions) return null;
    if (!Array.isArray(formattedOptions)) return formattedOptions[field.value] ?? null;

    const hasSubOptions = formattedOptions.some((option) => 'options' in option);
    const optionList = hasSubOptions ? formattedOptions.flatMap((group) => group.options) : formattedOptions;
    return Array.isArray(field.value)
      ? field.value.map((value) => optionList.find((option) => getOptionValue(option) === value))
      : (optionList.find((option) => getOptionValue(option) === field.value) ?? null);
  }, [field.value, formattedOptions, getOptionValue]);

  const onChange = (selectedOptionOrOptions, meta) => {
    const value = props.isMulti
      ? selectedOptionOrOptions.map((opt) => getOptionValue(opt))
      : isEmptyValue(selectedOptionOrOptions)
        ? null
        : getOptionValue(selectedOptionOrOptions);
    setTouched(true);
    setValue(value, true);
    props.onChange?.(value, meta);
  };

  const modifiedProps = {
    ...props,
    name,
    getOptionLabel,
    getOptionValue,
    onChange,
    instanceId: `react-select-${name}`,
    options: formattedOptions,
    value: selectedOptionOrOptions,
    onBlur: () => setTouched(true),
  };

  return <BaseSelectField {...modifiedProps} />;
};

SelectField.displayName = 'FormikSelectField';

export const AsyncSelectField = <
  Option extends CustomOptionProps,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  valueKey,
  labelKey,
  ...props
}: AsyncSelectProps<Option, IsMulti, Group>) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error(SelectField.displayName + ' with missing FieldWrapper component');
  }

  const [field, , { setValue, setTouched }] = useField(name);

  const getOptionValue = useCallback(props.getOptionValue ?? ((option) => option[valueKey === undefined ? 'value' : valueKey]), [
    props.getOptionValue,
    valueKey,
  ]);

  const getOptionLabel = useCallback(props.getOptionLabel ?? ((option) => option[labelKey === undefined ? 'label' : labelKey]), [
    props.getOptionLabel,
    labelKey,
  ]);

  const onChange = (selectedOptionOrOptions, meta) => {
    setTouched(true);
    setValue(selectedOptionOrOptions, true);
    props.onChange?.(selectedOptionOrOptions, meta);
  };

  const modifiedProps: AsyncProps<Option, IsMulti, Group> = {
    ...props,
    name,
    getOptionLabel,
    getOptionValue,
    onChange,
    instanceId: `react-select-${name}`,
    value: field.value,
    onBlur: () => setTouched(true),
  };

  return <BaseAsyncSelectField {...modifiedProps} />;
};

AsyncSelectField.displayName = 'FormikAsyncSelectField';
