import { CalendarIcon } from '@heroicons/react/24/outline';
import { FieldPostfix } from 'Shared/Components/Formik';
import { addDays, parseISO, startOfDay } from 'date-fns';
import { ForwardedRef, forwardRef } from 'react';
import DatePicker from 'react-datepicker';

export const CustomInput = forwardRef((props, ref: ForwardedRef<any>) => {
  const customProps = {
    ...props,
    onClick(e) {
      e.preventDefault();
    },
  };

  return (
    <div className="relative inline-block cursor-text">
      <input ref={ref} type="date" {...customProps} />
      <FieldPostfix children={<CalendarIcon className="h-5" />} />
    </div>
  );
});

export const CustomDatePicker = ({
  date,
  max,
  min,
  setDate,
  placeholderText,
}: {
  date: string | Date | null;
  max?: string | Date | null;
  min?: string | Date | null;
  setDate: (e: Date) => void;
  placeholderText?: string;
}) => {
  let maxDate;
  let minDate;

  if (max === 'today') {
    maxDate = startOfDay(new Date());
  } else if (max === 'now') {
    maxDate = new Date();
  } else if (max === 'tomorrow') {
    maxDate = addDays(startOfDay(new Date()), 1);
  } else {
    maxDate = max ? (typeof max === 'string' ? parseISO(max) : max) : null;
  }

  if (min === 'today') {
    minDate = startOfDay(new Date());
  } else if (min === 'now') {
    minDate = new Date();
  } else if (min === 'tomorrow') {
    minDate = addDays(startOfDay(new Date()), 1);
  } else {
    minDate = min ? (typeof min === 'string' ? parseISO(min) : min) : null;
  }

  return (
    <DatePicker
      customInput={<CustomInput />}
      dateFormat={'yyyy-MM-dd'}
      showPopperArrow={false}
      onChange={setDate}
      selected={date}
      maxDate={maxDate ?? undefined}
      minDate={minDate ?? undefined}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      todayButton="Today"
      popperClassName="z-10"
      placeholderText={placeholderText}
      className={`h-10.5 flex items-center justify-between shadow-input bg-gray-100 pl-4 spinners-none input-icon-mask
        text-blackish rounded placeholder-gray-800 text-sm py-2.5 w-44 border border-gray-600 focus-ring focus:bg-white focus:shadow:none
        cursor-text
        `}
    />
  );
};
