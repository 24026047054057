export default {
  colors: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  accentColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
    auto: 'auto',
  },
  animation: {
    none: 'none',
    spin: 'spin 1s linear infinite',
    ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
    pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    bounce: 'bounce 1s infinite',
    vertical_scroll: 'vertScroll linear 40s',
    fade_in: 'fadeIn 2s',
    fade_out: 'fadeOut 2s',
  },
  aria: {
    busy: 'busy="true"',
    checked: 'checked="true"',
    disabled: 'disabled="true"',
    expanded: 'expanded="true"',
    hidden: 'hidden="true"',
    pressed: 'pressed="true"',
    readonly: 'readonly="true"',
    required: 'required="true"',
    selected: 'selected="true"',
  },
  aspectRatio: {
    auto: 'auto',
    square: '1 / 1',
    video: '16 / 9',
  },
  backdropBlur: {
    '0': '0',
    none: '',
    sm: '4px',
    DEFAULT: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  backdropBrightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropContrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropGrayscale: {
    '0': '0',
    DEFAULT: '100%',
  },
  backdropHueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  backdropInvert: {
    '0': '0',
    DEFAULT: '100%',
  },
  backdropOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backdropSaturate: {
    '0': '0',
    '50': '.5',
    '100': '1',
    '150': '1.5',
    '200': '2',
  },
  backdropSepia: {
    '0': '0',
    DEFAULT: '100%',
  },
  backgroundColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
    'badge-blue': '#c1dff2',
    'badge-green': '#a6fcb5',
    'badge-red': '#f9c1c1',
  },
  backgroundImage: {
    none: 'none',
    'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
    'gradient-to-tr': 'linear-gradient(to top right, var(--tw-gradient-stops))',
    'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
    'gradient-to-br': 'linear-gradient(to bottom right, var(--tw-gradient-stops))',
    'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
    'gradient-to-bl': 'linear-gradient(to bottom left, var(--tw-gradient-stops))',
    'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
    'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))',
    'progress-container': 'linear-gradient(to right, #26c6da var(--progress), #f1f0f5 0)',
    'progress-text': 'linear-gradient(to right, white var(--progress), #6b6680 0)',
  },
  backgroundOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backgroundPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },
  backgroundSize: {
    auto: 'auto',
    cover: 'cover',
    contain: 'contain',
  },
  blur: {
    '0': '0',
    none: '',
    sm: '4px',
    DEFAULT: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  borderColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
    DEFAULT: '#ebeaef',
  },
  borderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  borderRadius: {
    none: '0px',
    sm: '0.125rem',
    DEFAULT: '0.3125rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  borderSpacing: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  borderWidth: {
    '0': '0px',
    '2': '2px',
    '3': '3px',
    '4': '4px',
    '8': '8px',
    DEFAULT: '1px',
  },
  boxShadow: {
    sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
    inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
    none: 'none',
    input: 'inset 0px 2px 2px rgba(212, 211, 219, 0.51)',
    circle: '0px 3px 2px -2px rgba(107, 98, 119, 0.3)',
    'big-circle': '0px 2px 60px',
  },
  boxShadowColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  brightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  caretColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  columns: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    auto: 'auto',
    '3xs': '16rem',
    '2xs': '18rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
  },
  container: {},
  content: {
    none: 'none',
  },
  contrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  cursor: {
    auto: 'auto',
    default: 'default',
    pointer: 'pointer',
    wait: 'wait',
    text: 'text',
    move: 'move',
    help: 'help',
    'not-allowed': 'not-allowed',
    none: 'none',
    'context-menu': 'context-menu',
    progress: 'progress',
    cell: 'cell',
    crosshair: 'crosshair',
    'vertical-text': 'vertical-text',
    alias: 'alias',
    copy: 'copy',
    'no-drop': 'no-drop',
    grab: 'grab',
    grabbing: 'grabbing',
    'all-scroll': 'all-scroll',
    'col-resize': 'col-resize',
    'row-resize': 'row-resize',
    'n-resize': 'n-resize',
    'e-resize': 'e-resize',
    's-resize': 's-resize',
    'w-resize': 'w-resize',
    'ne-resize': 'ne-resize',
    'nw-resize': 'nw-resize',
    'se-resize': 'se-resize',
    'sw-resize': 'sw-resize',
    'ew-resize': 'ew-resize',
    'ns-resize': 'ns-resize',
    'nesw-resize': 'nesw-resize',
    'nwse-resize': 'nwse-resize',
    'zoom-in': 'zoom-in',
    'zoom-out': 'zoom-out',
  },
  divideColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
    DEFAULT: '#ebeaef',
  },
  divideOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  divideWidth: {
    '0': '0px',
    '2': '2px',
    '3': '3px',
    '4': '4px',
    '8': '8px',
    DEFAULT: '1px',
  },
  dropShadow: {
    sm: '0 1px 1px rgb(0 0 0 / 0.05)',
    DEFAULT: ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
    md: ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
    lg: ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
    xl: ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
    '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
    none: '0 0 #0000',
  },
  fill: {
    none: 'none',
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  flex: {
    '1': '1 1 0%',
    auto: '1 1 auto',
    initial: '0 1 auto',
    none: 'none',
  },
  flexBasis: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
  },
  flexGrow: {
    '0': '0',
    DEFAULT: '1',
  },
  flexShrink: {
    '0': '0',
    DEFAULT: '1',
  },
  fontFamily: {
    sans: [
      'Poppins',
      'ui-sans-serif',
      'system-ui',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: ['ui-serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
    mono: ['Source Code Pro', 'ui-monospace', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
  },
  fontSize: {
    xs: [
      '0.812rem',
      {
        lineHeight: '0.986rem',
      },
    ],
    sm: [
      '0.875rem',
      {
        lineHeight: '1.062rem',
      },
    ],
    base: [
      '1rem',
      {
        lineHeight: '1.5rem',
      },
    ],
    lg: [
      '1.125rem',
      {
        lineHeight: '1.75rem',
      },
    ],
    xl: [
      '1.25rem',
      {
        lineHeight: '1.75rem',
      },
    ],
    '2xl': [
      '1.5rem',
      {
        lineHeight: '2rem',
      },
    ],
    '3xl': [
      '1.875rem',
      {
        lineHeight: '2.25rem',
      },
    ],
    '4xl': [
      '2.25rem',
      {
        lineHeight: '2.5rem',
      },
    ],
    '5xl': [
      '3rem',
      {
        lineHeight: '1',
      },
    ],
    '6xl': [
      '3.75rem',
      {
        lineHeight: '1',
      },
    ],
    '7xl': [
      '4.5rem',
      {
        lineHeight: '1',
      },
    ],
    '8xl': [
      '6rem',
      {
        lineHeight: '1',
      },
    ],
    '9xl': [
      '8rem',
      {
        lineHeight: '1',
      },
    ],
    xxs: [
      '0.5rem',
      {
        lineHeight: '0.8rem',
      },
    ],
  },
  fontWeight: {
    thin: '100',
    extralight: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  gap: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  gradientColorStops: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  gradientColorStopPositions: {
    '0%': '0%',
    '5%': '5%',
    '10%': '10%',
    '15%': '15%',
    '20%': '20%',
    '25%': '25%',
    '30%': '30%',
    '35%': '35%',
    '40%': '40%',
    '45%': '45%',
    '50%': '50%',
    '55%': '55%',
    '60%': '60%',
    '65%': '65%',
    '70%': '70%',
    '75%': '75%',
    '80%': '80%',
    '85%': '85%',
    '90%': '90%',
    '95%': '95%',
    '100%': '100%',
  },
  grayscale: {
    '0': '0',
    DEFAULT: '100%',
  },
  gridAutoColumns: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridAutoRows: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridColumn: {
    auto: 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridColumnEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    auto: 'auto',
  },
  gridColumnStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    auto: 'auto',
  },
  gridRow: {
    auto: 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridRowEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    auto: 'auto',
  },
  gridRowStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    auto: 'auto',
  },
  gridTemplateColumns: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    '7': 'repeat(7, minmax(0, 1fr))',
    '8': 'repeat(8, minmax(0, 1fr))',
    '9': 'repeat(9, minmax(0, 1fr))',
    '10': 'repeat(10, minmax(0, 1fr))',
    '11': 'repeat(11, minmax(0, 1fr))',
    '12': 'repeat(12, minmax(0, 1fr))',
    none: 'none',
    subgrid: 'subgrid',
  },
  gridTemplateRows: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    '7': 'repeat(7, minmax(0, 1fr))',
    '8': 'repeat(8, minmax(0, 1fr))',
    '9': 'repeat(9, minmax(0, 1fr))',
    '10': 'repeat(10, minmax(0, 1fr))',
    '11': 'repeat(11, minmax(0, 1fr))',
    '12': 'repeat(12, minmax(0, 1fr))',
    none: 'none',
    subgrid: 'subgrid',
  },
  height: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    full: '100%',
    screen: '100vh',
    svh: '100svh',
    lvh: '100lvh',
    dvh: '100dvh',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  hueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  inset: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    full: '100%',
  },
  invert: {
    '0': '0',
    DEFAULT: '100%',
  },
  keyframes: {
    spin: {
      to: {
        transform: 'rotate(360deg)',
      },
    },
    ping: {
      '75%, 100%': {
        transform: 'scale(2)',
        opacity: '0',
      },
    },
    pulse: {
      '50%': {
        opacity: '.5',
      },
    },
    bounce: {
      '0%, 100%': {
        transform: 'translateY(-25%)',
        animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
      },
      '50%': {
        transform: 'none',
        animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
      },
    },
    vertScroll: {
      '0%': {
        transform: 'translateY(0%)',
      },
      '100%': {
        transform: 'translateY(-100%)',
      },
    },
    fadeIn: {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 100,
      },
    },
    fadeOut: {
      '0%': {
        opacity: 100,
      },
      '100%': {
        opacity: 0,
      },
    },
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0em',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
  listStyleType: {
    none: 'none',
    disc: 'disc',
    decimal: 'decimal',
  },
  listStyleImage: {
    none: 'none',
  },
  margin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  lineClamp: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
  },
  maxHeight: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    none: 'none',
    full: '100%',
    screen: '100vh',
    svh: '100svh',
    lvh: '100lvh',
    dvh: '100dvh',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    'screen-9/10': '90vh',
    'screen-3/4': '75vh',
    'screen-2/3': '66vh',
    'screen-1/2': '50vh',
    'screen-1/3': '33vh',
    'screen-1/4': '25vh',
  },
  maxWidth: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    '160': '40rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    none: 'none',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    prose: '65ch',
    'screen-sm': '640px',
    'screen-md': '768px',
    'screen-lg': '1024px',
    'screen-xl': '1280px',
    'screen-2xl': '1536px',
    screen: '100vw',
    'screen-9/10': '90vw',
    'screen-3/4': '75vw',
    'screen-2/3': '66vw',
    'screen-1/2': '50vw',
    'screen-1/3': '33vw',
    'screen-1/4': '25vw',
    'screen-1/6': '16.6vw',
    'screen-3xl': '1850px',
  },
  minHeight: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    full: '100%',
    screen: '100vh',
    svh: '100svh',
    lvh: '100lvh',
    dvh: '100dvh',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    'screen-1/2': '50vh',
  },
  minWidth: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '26': '6.5rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    screen: '100vw',
    'screen-9/10': '90vw',
    'screen-3/4': '75vw',
    'screen-2/3': '66vw',
    'screen-1/2': '50vw',
    'screen-1/3': '33vw',
    'screen-1/4': '25vw',
    'screen-1/6': '16.6vw',
  },
  objectPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },
  opacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  order: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    first: '-9999',
    last: '9999',
    none: '0',
  },
  outlineColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  outlineOffset: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  outlineWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  padding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  placeholderColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  placeholderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  ringColor: {
    DEFAULT: '#3b82f6',
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  ringOffsetColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  ringOffsetWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  ringOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
    DEFAULT: '0.5',
  },
  ringWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    DEFAULT: '3px',
  },
  rotate: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
    '45': '45deg',
    '90': '90deg',
    '180': '180deg',
  },
  saturate: {
    '0': '0',
    '50': '.5',
    '100': '1',
    '150': '1.5',
    '200': '2',
  },
  scale: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
  },
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  scrollMargin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  scrollPadding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  sepia: {
    '0': '0',
    DEFAULT: '100%',
  },
  skew: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
  },
  space: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  spacing: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  stroke: {
    none: 'none',
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  strokeWidth: {
    '0': '0',
    '1': '1',
    '2': '2',
  },
  supports: {},
  data: {},
  textColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
    'badge-blue': '#529dff',
    'badge-green': '#2ba04c',
    'badge-red': '#ff5a5a',
  },
  textDecorationColor: {
    black: '#000',
    blackish: '#070319',
    blue: '#2facc8',
    current: 'currentColor',
    cyan: {
      DEFAULT: '#26c6da',
      highlight: '#00bcd3',
    },
    dark: {
      green: '#0b9',
    },
    gray: {
      '100': '#f1f0f5',
      '200': '#ebeaef',
      '300': '#e5e4e9',
      '400': '#e0dfe5',
      '500': '#dcd9e8',
      '600': '#c9c6d2',
      '700': '#a7a3b2',
      '750': '#938ea6',
      '800': '#6b6680',
      '850': '#69647e',
      '900': '#636363',
      '1000': '#494949',
    },
    green: '#2fc8ac',
    nav: {
      start: '#171f4b',
      end: '#00407b',
      highlight: '#b4bef6',
      text: '#c0c6e2',
      icon: '#6b739a',
    },
    orange: '#f97c1c',
    pink: '#f23082',
    red: '#ef4444',
    transparent: 'transparent',
    white: '#fff',
  },
  textDecorationThickness: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    auto: 'auto',
    'from-font': 'from-font',
  },
  textIndent: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
  },
  textOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '15': '0.15',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '35': '0.35',
    '40': '0.4',
    '45': '0.45',
    '50': '0.5',
    '55': '0.55',
    '60': '0.6',
    '65': '0.65',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '85': '0.85',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  textUnderlineOffset: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    auto: 'auto',
  },
  transformOrigin: {
    center: 'center',
    top: 'top',
    'top-right': 'top right',
    right: 'right',
    'bottom-right': 'bottom right',
    bottom: 'bottom',
    'bottom-left': 'bottom left',
    left: 'left',
    'top-left': 'top left',
  },
  transitionDelay: {
    '0': '0s',
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
  },
  transitionDuration: {
    '0': '0s',
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
    DEFAULT: '150ms',
  },
  transitionProperty: {
    none: 'none',
    all: 'all',
    DEFAULT: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
    colors: 'color, background-color, border-color, text-decoration-color, fill, stroke',
    opacity: 'opacity',
    shadow: 'box-shadow',
    transform: 'transform',
    width: 'width',
  },
  transitionTimingFunction: {
    DEFAULT: 'cubic-bezier(0.4, 0, 0.2, 1)',
    linear: 'linear',
    in: 'cubic-bezier(0.4, 0, 1, 1)',
    out: 'cubic-bezier(0, 0, 0.2, 1)',
    'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  translate: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    full: '100%',
  },
  size: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  width: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '75': '18.75rem',
    '80': '20rem',
    '96': '24rem',
    '100': '25rem',
    '160': '40rem',
    '192': '48rem',
    auto: 'auto',
    px: '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '7.5': '1.875rem',
    '10.5': '2.625rem',
    '11.5': '2.875rem',
    '82.5': '20.625rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
    screen: '100vw',
    svw: '100svw',
    lvw: '100lvw',
    dvw: '100dvw',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  willChange: {
    auto: 'auto',
    scroll: 'scroll-position',
    contents: 'contents',
    transform: 'transform',
  },
  zIndex: {
    '0': '0',
    '10': '10',
    '20': '20',
    '30': '30',
    '40': '40',
    '50': '50',
    auto: 'auto',
  },
};
