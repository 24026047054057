export const retentionOptions = [
  { label: 'Keep for 1 Month', value: 1 },
  { label: 'Keep for 3 Months', value: 3 },
  { label: 'Keep for 6 Months', value: 6 },
  { label: 'Keep for 12 Months', value: 12 },
  { label: 'Keep for 18 Months', value: 18 },
  { label: 'Keep for 24 Months', value: 24 },
  { label: 'Keep for 36 Months', value: 36 },
  { label: 'Use Manual Deletion', value: 0 },
];

export const retentionValues = retentionOptions.map((option) => option.value);
